table {
  font-size: 15px;
  width: 100%;
  margin-bottom: 1%;
}
.actions {
  display: flex;
  gap: 6px;
}
.actions div {
  cursor: pointer;
}
thead {
  border-radius: 5px 5px 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.29);
  width: 100%;
  padding: 10px;
  padding: 10px;
}
tr,
th,
td {
  text-align: center;
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}
.deleteicon_cardslist {
  color: red;
}
.whatsappicon {
  color: green;
}
th {
  border-right: 1px solid rgba(125, 125, 125, 0.602);
}
.main {
  height: 80vh;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 30px;
  margin-top: 1%;
}
tbody {
  border-radius: 0px 0px 5px 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-collapse: collapse;
  border-radius: 10px;
}
table {
  background-color: white;
  border-radius: 10px;
}
button {
  display: flex;
  gap: 10px;
  font-weight: 300;
  align-items: center;
}
admin_cardlist_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-content: center;
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
}
.admin_cardlist_searchinput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@media (max-width: 500px) {
  .main {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .admin_cardlist_search {
    flex-direction: column;
    gap: 10px;
  }
}
@media (min-width: 500px) and (max-width: 1300px) {
  .main {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
