.login_form {
  display: flex;
  text-align: center;
  align-items: center;
  gap: 50px;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
}
.image {
  width: 350px;
  height: 300px;
}
.cointiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  background: linear-gradient(
    180deg,
    rgba(212, 227, 221) 0%,
    rgba(240, 240, 240) 100%
  );
  height: 100vh;
  width: 100%;
}
.login_imgandloc {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.dr-radhika,
.dr-radhika * {
  box-sizing: border-box;
}

.dr-radhika {
  color: #1b949b;
  text-align: left;
  font-family: "Kodchasan-Bold", sans-serif;
  font-size: 38px;
  font-weight: 700;
  position: relative;
  width: 250px;
  height: 40px;
}
.multi-speciality-hospital-and,
.multi-speciality-hospital-and * {
  box-sizing: border-box;
}

.multi-speciality-hospital-and {
  color: #000000;
  text-align: left;
  font-family: "Kodchasan-Medium", sans-serif;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  letter-spacing: 1px;
}
.fertility-center-visakhapatnam,
.fertility-center-visakhapatnam * {
  box-sizing: border-box;
}

.fertility-center-visakhapatnam {
  color: #000000;
  text-align: left;
  font-family: "Kodchasan-Medium", sans-serif;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  letter-spacing: 0.5px;
}
.text_login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-left: 12px;
  padding-left: 12px;
}
.login_image {
  height: 400px;
  width: 400px;
}
@media (max-width: 500px) {
  * {
    font-size: 14px;
  }
  .login_form {
    margin: 10px;
    flex-direction: column;
  }
  .image {
    width: 150px;
    height: 150px;
  }
  .cointiner {
    gap: 30px;
  }
  .login_image {
    height: 300px;
    width: 300px;
  }
}

@media (min-width: 550px) and (max-width: 800px) {
  .image {
    width: 200px;
    height: 200px;
  }
  * {
    font-size: 16px;
  }
}
