* {
  font-size: 16px;
}
.logo {
  width: 80px;
  height: 60px;
  left: 0;
  margin-left: 10px;
  padding-left: 5px;
}

.navbar {
  width: 100%;
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 7px;
  border-top-left-radius: 0;
  border-top-right-radius: 0px;
}
.navbar-icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-item {
  font-size: 16px;
  display: none;
}
.login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-family: "Kodchasan";
}
.menu-item {
  display: none;
}
.topnavbar-items {
  top: 65px;
  left: -500px;
  position: fixed;
  background-color: white;
  z-index: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 7px;
  width: 80%;
  height: 90vh;
  transition: 1s ease;
  display: none;
  font-size: 10px;
}
.topnavbar-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
@media (max-width: 500px) {
  .menu-item {
    display: inline;
    font-size: 20px;
  }
  .topnavbar-items.open {
    display: block;
    top: 65px;
    left: 0;
  }
  .items li {
    font-size: 14px;
  }
  * {
    font-size: 14px;
  }
}
