.search_main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  margin-top: 1%;
}

.search_div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.error {
  border-color: red;
}
